// src/SignUpForm.js
import React, { useState } from 'react';
import { firestore } from './firebase';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await firestore.collection('emails').add({ email, timestamp: new Date() });
      setMessage('Thanks for signing up!');
      setEmail('');
    } catch (error) {
      setMessage('There was an error. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Your email"
        required
      />
      <button type="submit">Await Announcement</button>
      {message && <p>{message}</p>}
    </form>
  );
};

export default SignUpForm;
