import React from 'react';
import './App.css';
import { useForm } from 'react-hook-form';
import Footer from './components/Footer';
import SignUpForm from './signupform';

const App = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    // Store email address
    console.log(data.email);
  };

  return (
    <div className="App">
      {/* Add your background image, character PNGs, and other assets */}
      <h1>Strange Punk</h1>
      <h2>Watch This Space</h2>
      <SignUpForm />
      <Footer />
    </div>
  );
};

export default App;
