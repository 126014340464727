// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAkOA-j9ZVi99MfDurHA_h0NSI4fACgDGM",
    authDomain: "strangepunk-2ac13.firebaseapp.com",
    projectId: "strangepunk-2ac13",
    storageBucket: "strangepunk-2ac13.appspot.com",
    messagingSenderId: "705400383646",
    appId: "1:705400383646:web:103156b31b5a3ebc2d338f",
    measurementId: "G-44SSL592VP"
  };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics(app);
export const firestore = firebase.firestore();
